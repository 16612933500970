var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.data)?_c('div',{staticClass:"product-orders",class:{
    'product-orders--green': _vm.data.order.status.code === _vm.$store.state._status.CODE_ORDER_DELIVERED,
    'product-orders--yellow': _vm.data.order.status.code !== _vm.$store.state._status.CODE_ORDER_DELIVERED,
  }},[_c('ImgComponent',{staticClass:"product-orders__image",attrs:{"head_img":_vm.item_img,"width":"180","height":"200"}}),_c('div',{staticClass:"product-orders__content"},[_c('span',{staticClass:"product-orders__subtitle"},[_vm._v("\n      № "+_vm._s(_vm.data.id)+"\n      "),(_vm.data.order.purchased_at)?[_vm._v("от "+_vm._s(_vm._f("formatDate")(_vm.data.order.purchased_at)))]:_vm._e()],2),_c('span',{staticClass:"product-orders__title"},[(_vm.item.brand && _vm.item.brand.title)?_c('router-link',{attrs:{"to":{ name: 'catalog', query: { brand_id: _vm.item.brand.id, page_title: _vm.item.brand.title } }}},[_vm._v("\n        "+_vm._s(_vm.item.brand.title)+"\n      ")]):_vm._e(),(_vm.item.brand && _vm.item.brand.title)?_c('span',[_vm._v(" / ")]):_vm._e(),(_vm.item.title)?_c('router-link',{attrs:{"to":{
          name: 'product',
          params: { id: _vm.item.parent_id ? _vm.item.parent_id : _vm.item.id, link: _vm.item.link },
        }}},[_vm._v("\n        "+_vm._s(_vm.item.title)+"\n      ")]):_vm._e()],1),(_vm.data.order && _vm.data.order.status)?_c('span',{staticClass:"product-orders__status"},[_vm._v("\n      Статус: "+_vm._s(_vm.data.order.status.name)+"\n    ")]):_vm._e()]),(_vm.item.variation_one || _vm.item.variation_two || _vm.item.variation_three)?_c('div',{staticClass:"product-orders__subtitle-row"},[(_vm.item.variation_one)?_c('span',{staticClass:"product-orders__subtitle"},[_vm._v("\n      "+_vm._s(_vm.item.variation_one.attribute.title)+": "+_vm._s(_vm.item.variation_one.value.value)+"\n    ")]):_vm._e(),(_vm.item.variation_two)?_c('span',{staticClass:"product-orders__subtitle"},[_vm._v("\n      "+_vm._s(_vm.item.variation_two.attribute.title)+": "+_vm._s(_vm.item.variation_two.value.value)+"\n    ")]):_vm._e(),(_vm.item.variation_three)?_c('span',{staticClass:"product-orders__subtitle"},[_vm._v("\n      "+_vm._s(_vm.item.variation_three.attribute.title)+": "+_vm._s(_vm.item.variation_three.value.value)+"\n    ")]):_vm._e()]):_vm._e(),_c('div',{staticClass:"product-orders__prices"},[(_vm.data.sum)?_c('span',{staticClass:"product-orders__price"},[_vm._v("\n      "+_vm._s(_vm.formatPrice(_vm.data.sum))+"\n    ")]):_vm._e(),(_vm.data.item && _vm.data.item.join_share && _vm.data.item.join_share.share)?_c('span',{staticClass:"product-orders__old-price"},[_vm._v("\n      "+_vm._s(_vm._f("price_from_dollar")(_vm.data.item.price,_vm.$store.state))+"\n    ")]):_vm._e()])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }