<template>
  <div class="user-page__content">
    <SelectComponent
      v-model="activeSort"
      :clearable="false"
      :searchable="false"
      :options="sortTypes"
      label-name="title"
      title="Сортировать по"
      @input="changeSort"
    />
    <div class="sorts">
      <span class="sorts__title">Сортировать по: </span>
      <button
        class="sorts__item"
        type="button"
        v-for="(sort, i) in sortTypes"
        :key="i"
        @click="changeSort(sort)"
        :class="{
          'sorts__item--active': JSON.stringify(activeSort) === JSON.stringify(sort),
        }"
      >
        {{ sort.title }}
      </button>
    </div>
    <div v-if="showFavorites" :style="{ opacity: loading ? 0.7 : 1 }" class="user-page__favorite-items">
      <ProductCardComponent :data="item" v-for="(item, i) in favorites.data" :key="i" small />
    </div>
    <LoadingIndicator title="Загрузка" v-else-if="loadingData" />
    <span v-else-if="!loading">Нет товаров, добавленных в избранное</span>
    <PaginationComponent
      v-if="showPagination"
      :page="page"
      :total="Math.ceil(favorites.paginatorInfo.total / first)"
      @change="paginate"
    />
  </div>
</template>

<script>
import ProductCardComponent from "components/product/ProductCardComponent.vue";
import SelectComponent from "components/inputs/select/index.vue";
import PRODUCTS_PAGINATE from "@/graphql/queries/products_paginate.graphql";
import PaginationComponent from "components/Pagination.vue";
import LoadingIndicator from "components/LoadingIndicator.vue";

export default {
  name: "FavoriteTabComponent",
  data() {
    return {
      page: 1,
      first: 12,
      activeSort: undefined,
      loading: false,
      loadingData: false,
      sortTypes: [
        {
          ordering: {
            orderColumn: "in_popular",
            orderBy: "desc",
          },
          title: "Популярности",
        },
        {
          ordering: {
            orderColumn: "price",
            orderBy: "asc",
          },
          title: "Возрастанию цены",
        },
        {
          ordering: {
            orderColumn: "price",
            orderBy: "desc",
          },
          title: "Убыванию цены",
        },
        {
          ordering: {
            orderColumn: "updated_at",
            orderBy: "desc",
          },
          title: "Обновлению",
        },
      ],
    };
  },
  created() {
    this.loadingData = true;
    this.activeSort = this.sortTypes[0];
    this.$store
      .dispatch("GET_FAVORITES", {
        apollo: this.$apollo,
      })
      .then(() => {
        this.load();
      })
      .catch(() => {
        this.loadingData = false;
      });
  },
  computed: {
    favorites() {
      let favorites = JSON.parse(JSON.stringify(this.$store.state.auth.favorites_paginate)) || {};
      favorites.data =
        favorites && favorites.data
          ? favorites.data.filter((item) => this.favoritesIds.includes(item.id))
          : [];
      return favorites;
    },
    favoritesIds() {
      return this.$store.state.auth.favorites;
    },
    showFavorites() {
      return this.favorites && this.favorites.data && this.favorites.data.length;
    },
    showPagination() {
      return this.showFavorites && Math.ceil(this.favorites.paginatorInfo.total / this.first) > 1;
    },
  },
  methods: {
    async changeSort(sort) {
      this.activeSort = sort;
      this.page = 1;
      this.loading = true;
      await this.load();
    },
    async paginate(page) {
      if (!this.loading) {
        this.page = page;
        this.loading = true;
        await this.load();
        this.loading = false;
        window.scrollTo(0, 0);
      }
    },
    async load() {
      if (this.favoritesIds && this.favoritesIds.length) {
        await this.$apollo.provider.defaultClient
          .query({
            query: PRODUCTS_PAGINATE,
            variables: {
              ordering: [this.activeSort.ordering],
              ids: this.favoritesIds,
              first: this.first,
              page: this.page,
            },
            context: {
              headers: {
                Authorization: "Bearer " + this.$store.state.apollo_token,
              },
            },
          })
          .then(({ data }) => {
            this.$store.state.auth.favorites_paginate = data.products_paginate;
            this.loading = false;
            this.loadingData = false;
          })
          .catch(() => {
            this.loading = false;
            this.loadingData = false;
          });
      } else {
        this.$store.state.auth.favorites_paginate = {};
        this.loadingData = false;
        this.loading = false;
      }
    },
  },
  components: { LoadingIndicator, PaginationComponent, SelectComponent, ProductCardComponent },
};
</script>

<style lang="stylus">
@import "~@/styles/parts/sorts.styl"
.user-page {
  &__content {
    display grid
    grid-gap 24px
    +below(860px) {
      grid-gap 15px
    }

    > .select__container {
      +above(861px) {
        display none
      }
    }

    .sorts {
      +below(860px) {
        display none
      }
    }
  }

  &__favorite-items {
    display grid
    grid-template-columns repeat(4, 1fr)
    gap 24px
    +below(1200px) {
      grid-template-columns repeat(3, 1fr)
    }
    +below(1024px) {
      gap 8px
      grid-template-columns repeat(2, 1fr)
    }
    +below(470px) {
      grid-template-columns 1fr
    }
  }
}
</style>
