<template>
  <div class="user-page-aside">
    <SelectComponent
      @input="$emit('input', $event)"
      :value="value"
      :clearable="false"
      :searchable="false"
      :options="tabs"
      label-name="title"
      title="Вкладка"
    />
    <a
      href="#"
      v-for="(link, i) in tabs"
      :key="i"
      @click.prevent="$emit('input', link)"
      class="user-page-aside__link"
      :class="{ 'user-page-aside__link--active': JSON.stringify(value) === JSON.stringify(link) }"
    >
      <RemixIconComponent :category="link.icon.category" :name="link.icon.name" />
      <span>{{ link.title }}</span>
    </a>
  </div>
</template>

<script>
import RemixIconComponent from "components/RemixIconComponent.vue";
import SelectComponent from "components/inputs/select/index.vue";

export default {
  name: "UserPageAsideComponent",
  props: {
    tabs: Array,
    value: Object,
  },
  components: { SelectComponent, RemixIconComponent },
};
</script>

<style lang="stylus">
.user-page-aside {
  display grid
  grid-gap 2px
  align-items start
  +above(861px) {
    .select {
      display none
    }
  }

  &__link {
    border: 1px solid transparent;
    box-sizing: border-box;
    border-radius: var(--small_radius);
    padding 16px
    display flex
    align-items center
    gap 8px
    font-weight: normal;
    font-size: 0.875em;
    line-height: 20px;
    color var(--body-color-muted)
    background: var(--blue-100);
    width 100%
    +below(860px) {
      display none
    }

    &:hover {
      border-color var(--accent)
      color var(--body-color-muted)
    }

    .icon {
      width 20px
      height 20px

      svg path {
        fill var(--body-color-filled)
      }
    }

    &--active {
      border-color var(--blue-300)
      background: var(--white);
      color var(--accent)

      .icon svg path {
        fill var(--accent)
      }
    }
  }
}
</style>
