<template>
  <div class="user-card">
    <template v-if="user">
      <ImgComponent class="user-card__image" :src="$store.state._env.MEDIA_ENDPOINT + image" v-if="image" />
      <ImgComponent class="user-card__image" :head_img="user.head_img" v-else />
      <div class="user-card__content">
        <span v-if="getUserFio()" class="user-card__title">{{ getUserFio() }}</span>
        <span v-else class="user-card__title">ФИО неизвестно</span>
        <button @click.prevent="$refs.input.click()" type="button" class="btn btn--sm btn--hollow-dark">
          Изменить фото
        </button>
      </div>
      <div class="user-card__total">
        <span class="user-card__total-title">Всего покупок на сумму</span>
        <span class="user-card__total-value"> {{ getOrdersSum() | price_from_dollar }} </span>
      </div>
      <input type="file" accept="image/*" ref="input" v-show="false" @input="showCropperModal" />
    </template>
  </div>
</template>

<script>
import CropImageModal from "components/modals/components/CropImageModal.vue";

export default {
  name: "UserCardComponent",
  data() {
    return {
      image: null,
    };
  },
  computed: {
    user() {
      return this.$store.state.auth.user;
    },
    orders() {
      return this.$store.state.auth.orders;
    },
  },
  methods: {
    getOrdersSum() {
      return this.user ? this.user.orders_sum || 0 : 0;
    },
    getUserFio() {
      return [this.user?.surname, this.user?.name, this.user?.patronymic].filter((a) => a).join(" ");
    },
    handleCallback(e) {
      this.$refs.input.type = "";
      this.$refs.input.type = "file";
      this.image = e.ImageUploadCrop.path;
      let image = e.ImageUploadCrop;
      delete image.__typename;
      delete image.type;
      this.$emit("change", e.ImageUploadCrop);
    },
    showCropperModal(e) {
      const reader = new FileReader();
      reader.onloadend = () => {
        this.$store.state._modals.push({
          component: CropImageModal,
          options: {
            data: {
              imageBase64: reader.result,
              image: e.target.files[0],
            },
            callback: this.handleCallback,
          },
        });
      };
      if (e.target.files[0]) {
        reader.readAsDataURL(e.target.files[0]);
      }
    },
  },
};
</script>

<style lang="stylus">
.user-card {
  background: var(--blue-200);
  border-radius: var(--main_radius);
  padding 16px
  display grid
  grid-gap 16px
  align-items start
  grid-template-columns 100px 1fr auto
  min-height 132px
  +below(640px) {
    grid-template-columns 1fr
    justify-content center
    align-items center
  }

  &__image {
    width 100px
    height 100px
    border-radius 100%
    object-fit cover
    object-position center
    +below(640px) {
      margin auto
    }
  }

  &__content {
    display flex
    gap 8px
    flex-direction column
    align-items flex-start
    justify-content center
    margin auto 0
    +below(640px) {
      margin auto
      flex-direction column
      align-items center
    }
  }

  &__title {
    font-weight: 500;
    font-size: 0.875em;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: var(--body-color);
  }

  &__total {
    display flex
    flex-direction column
    justify-content flex-start
    align-items flex-end
    +below(640px) {
      justify-content center
      align-items center
    }

    &-title {
      font-weight: 500;
      font-size: 0.875em;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: var(--body-color);
      margin 0
    }

    &-value {
      font-weight: normal;
      font-size: 2.25em;
      line-height: 50px;
      display: flex;
      align-items: center;
      color: var(--body-color);
      margin 0
    }
  }
}
</style>
