<template>
  <div class="loading-indicator">
    <span>{{ title }}</span>
    <div class="loading-indicator__dots">
      <span :key="i" v-for="i in dots">.</span>
    </div>
  </div>
</template>

<script>
/**
 * Текст "Подождите" с динамическими точками
 */
export default {
  name: "LoadingIndicator",
  props: {
    title: {
      type: String,
      default: "Подождите, идет загрузка",
    },
  },
  data() {
    return {
      dots: 0,
      interval: null,
    };
  },
  created() {
    this.interval = setInterval(() => {
      if (this.dots >= 3) {
        this.dots = 0;
      } else {
        this.dots += 1;
      }
    }, 100);
  },
};
</script>

<style lang="stylus">
.loading-indicator {
  display inline-flex
  align-items center
  position relative
}
</style>
