<template>
  <div class="user-page__content">
    <SelectComponent
      v-model="activeSort"
      :clearable="false"
      :searchable="false"
      :options="sortTypes"
      label-name="title"
      title="Сортировать по"
      @input="changeSort"
    />
    <div class="sorts">
      <span class="sorts__title">Сортировать по: </span>
      <button
        class="sorts__item"
        type="button"
        v-for="(sort, i) in sortTypes"
        :key="i"
        @click="changeSort(sort)"
        :class="{
          'sorts__item--active': JSON.stringify(activeSort) === JSON.stringify(sort),
        }"
      >
        {{ sort.title }}
      </button>
    </div>
    <div class="user-page__orders-content">
      <InputComponent @input="searchInput" v-model.trim="query" placeholder="Я ищу..." icon-position="left" />
      <div :style="{ opacity: loading ? 0.7 : 1 }" v-if="showOrders" class="user-page__orders-items">
        <ProductCardOrdersVersionComponent v-for="(item, i) in orders.data" :key="i" :data="item" />
      </div>
      <span v-else-if="query">По данному запросу ничего не найдено.</span>
      <LoadingIndicator title="Загрузка" v-else-if="loading" />
      <span v-else>Заказы отсутствуют.</span>
      <PaginationComponent
        v-if="showPagination"
        :page="page"
        :total="Math.ceil(orders.paginatorInfo.total / first)"
        @change="paginate"
      />
    </div>
  </div>
</template>

<script>
import SelectComponent from "components/inputs/select/index.vue";
import InputComponent from "components/inputs/InputComponent.vue";
import ProductCardOrdersVersionComponent from "components/product/ProductCardOrdersVersionComponent.vue";
import MY_ORDERS_PAGINATE from "gql/auth/my_orders_paginate.graphql";
import PaginationComponent from "components/Pagination.vue";
import LoadingIndicator from "components/LoadingIndicator.vue";

export default {
  name: "OrdersTabComponent",
  data() {
    return {
      page: 1,
      first: 10,
      loading: false,
      inputDebounceTimeout: null,
      activeSort: undefined,
      query: undefined,
      sortTypes: [
        {
          code: null,
          title: "Все",
        },
        {
          code: this.$store.state._status.CODE_ORDER_SUCCESS,
          title: "В обработке",
        },
        {
          code: this.$store.state._status.CODE_ORDER_IN_DELIVERY,
          title: "В пути",
        },
        {
          code: this.$store.state._status.CODE_ORDER_DELIVERED,
          title: "Завершенные",
        },
      ],
    };
  },
  created() {
    this.activeSort = this.sortTypes[0];
    this.loading = true;
    this.load();
  },
  computed: {
    orders() {
      return this.$store.state.auth.orders_paginate;
    },
    showOrders() {
      return this.orders && this.orders.data && this.orders.data.length;
    },
    showPagination() {
      return this.showOrders && Math.ceil(this.orders.paginatorInfo.total / this.first) > 1;
    },
  },
  methods: {
    searchInput() {
      if (this.inputDebounceTimeout) {
        clearTimeout(this.inputDebounceTimeout);
      }
      this.loading = true;
      this.inputDebounceTimeout = setTimeout(() => {
        this.page = 1;
        this.load();
      }, 500);
    },
    async changeSort(sort) {
      this.activeSort = sort;
      this.page = 1;
      this.loading = true;
      await this.load();
    },
    async paginate(page) {
      if (!this.loading) {
        this.page = page;
        this.loading = true;
        await this.load();
        this.loading = false;
        window.scrollTo(0, 0);
      }
    },
    async load() {
      await this.$apollo.provider.defaultClient
        .query({
          query: MY_ORDERS_PAGINATE,
          fetchPolicy: "no-cache",
          variables: {
            status: this.activeSort.code,
            title: this.query,
            first: this.first,
            page: this.page,
          },
          context: {
            headers: {
              Authorization: "Bearer " + this.$store.state.apollo_token,
            },
          },
        })
        .then(({ data }) => {
          this.$store.state.auth.orders_paginate = data.my_orders_paginate;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
  components: {
    LoadingIndicator,
    PaginationComponent,
    ProductCardOrdersVersionComponent,
    InputComponent,
    SelectComponent,
  },
};
</script>

<style lang="stylus">
@import "~@/styles/parts/sorts.styl"
.user-page {
  &__content {
    display grid
    grid-gap 24px
    +below(860px) {
      grid-gap 15px
    }

    > .select__container {
      +above(861px) {
        display none
      }
    }

    .sorts {
      +below(860px) {
        display none
      }
    }
  }

  &__orders-content {
    display grid
    grid-gap 15px
  }

  &__orders-items {
    display grid
    gap 4px
    transition var(--transition)
  }
}
</style>
