<template>
  <div class="user-page__content">
    <UserCardComponent @change="handleImageUpload" />
    <div class="form">
      <div class="form__row">
        <div class="form__col-6">
          <InputComponent
            v-model.trim="form.name.value"
            :error="form.name.error"
            title="ФИО"
            placeholder="ФИО"
          />
        </div>
        <div class="form__col-6">
          <InputComponent
            v-model.trim="form.email.value"
            :error="form.email.error"
            title="Электронная почта"
            placeholder="Email"
          />
        </div>
        <div class="form__col-6">
          <InputComponent
            v-model.trim="form.birthday.value"
            :error="form.birthday.error"
            title="День рождения"
            mask="##.##.####"
            placeholder="ДД.ММ.ГГГГ"
          />
        </div>
        <div class="form__col-6">
          <InputComponent
            v-model.trim="form.sex.value"
            :error="form.sex.error"
            title="Пол"
            placeholder="Пол"
          />
        </div>
      </div>
      <div class="user-page__form-footer">
        <button @click="profileUpdate" type="button" class="btn btn--lg btn--main-inv">
          <template v-if="profileUpdateLoading">Сохранение...</template>
          <template v-else>Сохранить</template>
        </button>
      </div>
    </div>
    <div class="form">
      <div class="form__row">
        <div class="form__col-6">
          <InputComponent
            v-model="form.phone.value"
            :error="form.phone.error"
            title="Номер телефона"
            placeholder="+7 (___) ___-__-__"
            mask="+7 (###) ### - ## - ##"
          />
        </div>
      </div>
      <div class="user-page__form-footer">
        <button @click="phoneUpdate" type="button" class="btn btn--lg btn--main-inv">
          <template v-if="phoneUpdateLoading">Сохранение...</template>
          <template v-else>Изменить</template>
        </button>
      </div>
    </div>
    <button @click="logout" type="button" class="user-page__logout btn btn--lg btn--red">
      Выйти из аккаунта
    </button>
  </div>
</template>

<script>
import UserCardComponent from "views/user/components/UserCardComponent.vue";
import InputComponent from "components/inputs/InputComponent.vue";
import USER_PROFILE_UPDATE from "gql/auth/UserProfileUpdate.graphql";
import USER_PHONE_UPDATE from "gql/auth/UserPhoneUpdate.graphql";
import PhoneConfirmModal from "components/modals/components/PhoneConfirmModal.vue";
import moment from "moment";
import axios from "axios";

export default {
  name: "ProfileTabComponent",
  data() {
    return {
      profileUpdateLoading: false,
      phoneUpdateLoading: false,
      form: {
        name: {
          value: undefined,
          message: undefined,
          error: undefined,
        },
        email: {
          value: undefined,
          message: undefined,
          error: undefined,
        },
        phone: {
          value: undefined,
          message: undefined,
          error: undefined,
        },
        birthday: {
          value: undefined,
          message: undefined,
          error: undefined,
        },
        sex: {
          value: undefined,
          message: undefined,
          error: undefined,
        },
        head_img: {
          value: undefined,
          error: undefined,
        },
      },
    };
  },
  mounted() {
    this.setProfileData();
  },
  watch: {
    "$store.state.auth.user"() {
      this.setProfileData();
    },
  },
  computed: {
    user() {
      return this.$store.state.auth.user;
    },
  },
  methods: {
    logout() {
      this.$store.state.apollo_token = undefined;
      this.$store.state.auth.user = null;
      axios({
        method: "post",
        url: "/token",
        data: {
          token: null,
        },
        headers: {
          "Content-Type": "application/json",
        },
      }).then(() => {
        if (this.$route.name === "home") {
          location.reload();
          return;
        }
        this.$router.push({ name: "home" }).then(() => {
          location.reload();
        });
      });
    },
    setProfileData() {
      if (this.user) {
        this.form.name.value = this.getUserFio();
        this.form.email.value = this.user.email;
        if (this.user.birthday) this.form.birthday.value = moment(this.user.birthday).format("DD.MM.YYYY");
        this.form.sex.value = this.user.sex;
        this.form.phone.value = this.user.phone;
      }
    },
    async profileUpdate() {
      if (!this.profileUpdateLoading) {
        this.resetErrors("form");
        this.profileUpdateLoading = true;
        let birthday;
        if (this.form.birthday.value) {
          birthday = moment(this.form.birthday.value, "DD.MM.YYYY");
        }
        if (birthday && !birthday.isValid()) {
          this.form.birthday.error = "Введите правильную дату";
          this.profileUpdateLoading = false;
          return;
        }
        let fioArr = this.form.name.value.split(" ");
        await this.$apollo.provider.defaultClient
          .mutate({
            mutation: USER_PROFILE_UPDATE,
            variables: {
              surname: fioArr[0] || "",
              name: fioArr[1] || "",
              patronymic: fioArr.slice(2).join(" ") || "",
              email: this.form.email.value || "",
              birthday: birthday ? birthday.format("YYYY-MM-DD HH:mm:ss") : null,
              head_img: this.form.head_img.value,
            },
            context: {
              headers: {
                Authorization: "Bearer " + this.$store.state.apollo_token,
              },
            },
          })
          .then(({ data }) => {
            if (data && data.UserProfileUpdate) {
              this.$store.state.auth.user = data.UserProfileUpdate.user;
              this.$notify({
                title: "Сохранено",
                text: data.UserProfileUpdate.message,
                duration: 5000,
                speed: 200,
                type: "success",
              });
            }
            this.profileUpdateLoading = false;
          })
          .catch(({ graphQLErrors }) => {
            this.profileUpdateLoading = false;
            this.parseGqlErrors(graphQLErrors, "form");
          });
      }
    },
    phoneUpdate() {
      if (!this.loading) {
        this.loading = true;
        this.$apollo.provider.defaultClient
          .mutate({
            mutation: USER_PHONE_UPDATE,
            variables: {
              phone: "+" + this.form.phone.value.replace(/\D+/g, ""),
            },
            context: {
              headers: {
                Authorization: "Bearer " + this.$store.state.apollo_token,
              },
            },
          })
          .then(() => {
            this.loading = false;
            // показать модалку с вводом кода
            this.$store.state._modals.push({
              component: PhoneConfirmModal,
              options: {},
            });
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
    resetErrors(form) {
      Object.keys(this[form]).forEach((key) => {
        this[form][key].error = undefined;
      });
    },
    parseGqlErrors(graphQLErrors) {
      graphQLErrors.forEach((err) => {
        if (err.extensions.category === "validation") {
          Object.keys(err.extensions.validation).forEach((key) => {
            if (this.form[key]) {
              this.form[key].error = err.extensions.validation[key][0];
            }
          });
        }
      });
    },
    handleImageUpload(event) {
      this.form.head_img.value = event;
    },
    getUserFio() {
      return [this.user?.surname, this.user?.name, this.user?.patronymic].filter((a) => a).join(" ");
    },
  },
  components: { InputComponent, UserCardComponent },
};
</script>

<style lang="stylus">
.user-page {
  .form__row {
    .input {
      width 100%
    }

    .form__col-6 {
      +below(860px) {
        gap 16px
        flex-basis calc(100% - 16px)
      }
    }
  }

  &__form-footer {
    display: flex;
    justify-content space-between
    align-items center
    +below(860px) {
      gap 8px
      flex-direction column-reverse
      align-content space-between
      justify-content space-between
      button {
        width 100%
        justify-content center
      }
    }
  }

  &__content {
    display grid
    grid-gap 16px
    align-items start
  }

  &__logout {
    justify-self end
  }
}
</style>
