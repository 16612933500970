<template>
  <div class="user-page page">
    <h1 class="user-page__title">Профиль пользователя</h1>
    <div class="user-page__body">
      <UserPageAsideComponent v-model="activeTab" :tabs="asideTabs" />
      <component :is="activeTab.tab" />
    </div>
  </div>
</template>

<script>
import UserPageAsideComponent from "views/user/components/UserPageAsideComponent.vue";
import ProfileTabComponent from "views/user/components/ProfileTabComponent.vue";
import FavoriteTabComponent from "views/user/components/FavoriteTabComponent.vue";
import OrdersTabComponent from "views/user/components/OrdersTabComponent.vue";
import USER_PAGE from "@/graphql/pages/UserPage.graphql";

export default {
  name: "UserPage",
  data() {
    return {
      activeTab: undefined,
      asideTabs: [
        {
          id: 0,
          icon: {
            category: "User",
            name: "user-line",
          },
          title: "Личные даные",
          tab: ProfileTabComponent,
        },
        {
          id: 1,
          icon: {
            category: "Document",
            name: "survey-line",
          },
          title: "Мои заказы",
          tab: OrdersTabComponent,
        },
        {
          id: 2,
          icon: {
            category: "Health",
            name: "heart-3-line",
          },
          title: "Избранное",
          tab: FavoriteTabComponent,
        },
      ],
    };
  },
  created() {
    this.activeTab = this.$route.params.id
      ? this.asideTabs[parseInt(this.$route.params.id)]
      : this.asideTabs[0];
  },
  mounted() {
    // Здесь грузится только история покупок т.к. личные данные грузятся в App.vue
    // без этих данных вход на эту страницу невозможен/не имеет смысла
    this.$apollo.provider.defaultClient
      .query({
        query: USER_PAGE,
        context: {
          headers: {
            Authorization: "Bearer " + this.$store.state.apollo_token,
          },
        },
      })
      .then(({ data }) => {
        this.$store.state.auth.orders = data.my_orders;
        this.$store.state.categories = data.categories;
        this.$store.state.pages = data.pages;
        this.$store.state.marketplace = data.marketplace;
        if (data.exchange_rates) this.$store.state.exchange_rates = data.exchange_rates;
      });
  },
  components: { UserPageAsideComponent },
  metaInfo: {
    title: "Личный кабинет",
  },
};
</script>

<style lang="stylus">
.user-page {
  padding 32px 15px 96px
  display grid
  align-items start
  grid-gap 32px
  +below(860px) {
    padding 15px
    grid-gap 15px
  }

  &__title {
    font-weight: normal;
    font-size: 2.25em;
    line-height: 50px;
    display: flex;
    align-items: center;
    color: var(--body-color);
    max-width var(--main_width)
    width 100%
    margin 0 auto
  }

  &__body {
    display grid
    align-items start
    grid-template-columns 3fr 9fr
    grid-gap 24px
    max-width var(--main_width)
    width 100%
    margin 0 auto
    +below(1024px) {
      grid-template-columns 4fr 8fr
    }
    +below(860px) {
      grid-template-columns 1fr
    }
    +below(540px) {
      grid-gap 15px
    }
  }
}
</style>
