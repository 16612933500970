<template>
  <div
    v-if="data"
    :class="{
      'product-orders--green': data.order.status.code === $store.state._status.CODE_ORDER_DELIVERED,
      'product-orders--yellow': data.order.status.code !== $store.state._status.CODE_ORDER_DELIVERED,
    }"
    class="product-orders"
  >
    <ImgComponent :head_img="item_img" width="180" height="200" class="product-orders__image" />
    <div class="product-orders__content">
      <span class="product-orders__subtitle">
        № {{ data.id }}
        <template v-if="data.order.purchased_at">от {{ data.order.purchased_at | formatDate }}</template>
      </span>
      <span class="product-orders__title">
        <router-link
          v-if="item.brand && item.brand.title"
          :to="{ name: 'catalog', query: { brand_id: item.brand.id, page_title: item.brand.title } }"
        >
          {{ item.brand.title }}
        </router-link>
        <span v-if="item.brand && item.brand.title"> / </span>
        <router-link
          v-if="item.title"
          :to="{
            name: 'product',
            params: { id: item.parent_id ? item.parent_id : item.id, link: item.link },
          }"
        >
          {{ item.title }}
        </router-link>
      </span>
      <span v-if="data.order && data.order.status" class="product-orders__status">
        Статус: {{ data.order.status.name }}
      </span>
    </div>
    <div
      v-if="item.variation_one || item.variation_two || item.variation_three"
      class="product-orders__subtitle-row"
    >
      <span v-if="item.variation_one" class="product-orders__subtitle">
        {{ item.variation_one.attribute.title }}: {{ item.variation_one.value.value }}
      </span>
      <span v-if="item.variation_two" class="product-orders__subtitle">
        {{ item.variation_two.attribute.title }}: {{ item.variation_two.value.value }}
      </span>
      <span v-if="item.variation_three" class="product-orders__subtitle">
        {{ item.variation_three.attribute.title }}: {{ item.variation_three.value.value }}
      </span>
    </div>
    <div class="product-orders__prices">
      <span v-if="data.sum" class="product-orders__price">
        {{ formatPrice(data.sum) }}
      </span>
      <span
        v-if="data.item && data.item.join_share && data.item.join_share.share"
        class="product-orders__old-price"
      >
        {{ data.item.price | price_from_dollar($store.state) }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductCardOrdersVersionComponent",
  props: {
    data: Object,
  },
  computed: {
    item() {
      return this.data.item;
    },
    item_img() {
      if (this.item.head_img) {
        return this.item.head_img;
      }
      if (this.item.images && this.item.images.length) {
        return this.item.images[0];
      }
      return null;
    },
  },
  methods: {
    formatPrice(price) {
      return new Intl.NumberFormat("ru-RU", {
        style: "currency",
        currency: "RUB",
        maximumFractionDigits: 0,
      }).format(price);
    },
  },
};
</script>

<style lang="stylus">
.product-orders {
  display grid
  grid-gap 16px
  grid-template-columns 90px 1fr auto
  align-items center
  background: var(--white);
  border-radius: var(--main_radius);
  padding 16px
  padding-left 36px
  position relative
  border: 1px solid var(--blue-400);
  transition var(--transition)
  +below(520px) {
    grid-template-areas "a b" "c d"
    grid-template-columns 90px auto
    align-items start
  }

  &:hover {
    border-color var(--accent)
  }

  &:before {
    absolute left 16px top 16px
    height calc(100% - 32px)
    width 4px
    content ""
    border-radius var(--small_radius)
    background var(--blue-400)
  }

  &--green:before {
    background var(--green)
  }

  &--yellow:before {
    background var(--yellow)
  }

  &--red:before {
    background var(--red-500)
  }

  & > &__subtitle-row {
    +above(521px) {
      display none
    }
    +below(520px) {
      grid-area c
      flex-direction column
      align-items flex-start
      gap 8px
      margin-left: -20px;
    }
  }

  &__image {
    width 90px
    height 100px
    object-fit cover
    object-position center
    border-radius var(--small_radius)
    +below(520px) {
      grid-area a
    }
  }

  &__content {
    display: grid;
    grid-gap: 4px;
    +below(520px) {
      grid-area b
      & > .product-orders__subtitle-row {
        display none
      }
    }
  }

  &__status,
  &__title * {
    font-weight: normal;
    font-size: 0.875em;
    line-height: 24px;
    color: var(--body-color);
  }

  &__subtitle {
    font-weight: normal;
    font-size: 0.75em;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: var(--gray-300);
  }

  &__subtitle-row {
    display flex
    align-items center
    gap 16px
  }

  &__prices {
    display flex
    flex-direction column
    align-items flex-start
    justify-content center
    padding 0 8px 8px
    +below(520px) {
      grid-area d
      align-items flex-end
      justify-content flex-start
    }
  }

  &__price {
    font-weight: 600;
    font-size: 1.125em;
    line-height: 24px;
    color: var(--gray-900);
  }

  &__old-price {
    font-weight: normal;
    font-size: 0.75em;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-decoration-line: line-through;
    color: var(--gray-300);
  }
}
</style>
