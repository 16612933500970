<template>
  <div class="default-modal login-modal">
    <div class="default-modal__header">
      <h3 class="default-modal__title">Ввод кода подтверждения</h3>
      <button type="button" class="default-modal__close" @click="$emit('close')">
        <RemixIconComponent category="System" name="close-line" />
      </button>
    </div>
    <div class="default-modal__body login-modal__body">
      <InputComponent
        v-model="code"
        :error="error"
        title="Введите код подтверждения"
        placeholder="Код из смс"
        mask="######"
        autofocus
      />
    </div>
    <div class="default-modal__footer login-modal__footer">
      <button type="button" class="btn btn--md btn--main" @click="phoneConfirm">
        <FileIconComponent v-if="loading" name="loading" />
        <template v-else>Подтвердить</template>
      </button>
    </div>
  </div>
</template>

<script>
import RemixIconComponent from "components/RemixIconComponent.vue";
import InputComponent from "components/inputs/InputComponent.vue";
import USER_PHONE_CONFIRM from "gql/auth/UserPhoneConfirm.graphql";
import FileIconComponent from "components/FileIconComponent.vue";
import axios from "axios";

export default {
  name: "PhoneConfirmModal",
  data() {
    return {
      code: null,
      loading: false,
      error: null,
    };
  },
  methods: {
    phoneConfirm() {
      if (!this.loading) {
        this.loading = true;
        this.$apollo.provider.defaultClient
          .mutate({
            mutation: USER_PHONE_CONFIRM,
            variables: {
              code: parseInt(this.code),
            },
          })
          .then(({ data }) => {
            if (data && data.UserPhoneConfirm) {
              this.$store.state.auth.user = data.UserPhoneConfirm.user;
              this.$store.state.apollo_token = data.UserPhoneConfirm.token;
              // Сохраняю токен в сессии
              axios({
                method: "post",
                url: "/token",
                data: {
                  token: data.UserPhoneConfirm.token,
                },
                headers: {
                  "Content-Type": "application/json",
                },
              }).then(() => {
                this.loading = false;
                this.$emit("close");
              });
            }
          })
          .catch(() => {
            this.loading = false;
            this.error = "Введен неправильный код подтверждения";
          });
      }
    },
  },
  components: { FileIconComponent, InputComponent, RemixIconComponent },
};
</script>

<style lang="stylus">
.login-modal {
  &__inputs {
    display grid
    grid-gap 4px
    width 100%
  }

  &__inputs-list {
    display flex
    align-items center
    gap 8px
    width 100%

    .input:first-child {
      width 110px
    }

    .input:last-child {
      width 100%
    }
  }

  &__inputs-title {
    display flex
    align-items center
    gap 8px
    width 100%
    font-weight: normal;
    font-size: 0.875em;
    line-height: 20px;
    color: var(--body-color-muted);
  }

  &__body > .input {
    width 100%
  }

  &__footer .btn {
    width 100%
    justify-content center
  }
}
</style>
